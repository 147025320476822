<template>
    <div>
        <v-row align="start" justify="start" dark>
            <v-col cols="12" class="d-flex flex-start">
                <div class="text-h6">Лицензии</div>

                <v-btn color="purple darken-2" class="ml-auto white--text" @click.prevent.stop="dialog.activate = true">
                    <v-icon left dark>
                        mdi-plus
                    </v-icon>
                    <span>Активировать ключ</span>
                </v-btn>
            </v-col>
        </v-row>

        <v-row align="start" justify="start" dark>
            <v-col v-if="_.size(items) > 0" cols="12">
                <v-card v-for="item in items" :key="item.id" class="mb-4">
                    <v-card-title>{{ _.get(item, 'license.code.license_type.name', 'Неизвестный тип лицензии') }}</v-card-title>
                    <v-card-subtitle>{{ _.get(item, 'license.code.value', 'Ключ не указан.') }}</v-card-subtitle>
                    <v-card-subtitle class="my-1 py-1">
                        <v-chip
                          color="green"
                          text-color="white"
                          label
                          small
                          class="mr-2 mb-2"
                        >
                          Дата активации: {{ _.get(item, 'license.created_at_datetime', 'Не указана') }}
                        </v-chip>
                        <v-chip
                          color="info"
                          text-color="white"
                          label
                          small
                          class="mr-2 mb-2"
                        >
                          Окончание срока действия: {{ _.get(item, 'license.expires_at_datetime', 'Не указана') }}
                        </v-chip>
                        <v-chip
                            v-if="isExpired(item)"
                            color="black"
                            text-color="white"
                            label
                            small
                            class="mb-2"
                        >
                            Cрок действия лицензии истек
                        </v-chip>
                        <v-chip
                            v-else-if="isExpiresSoon(item)"
                            color="warning"
                            text-color="white"
                            label
                            small
                            class="mb-2"
                        >
                            Cрок действия лицензии истекает менее чем через месяц
                        </v-chip>
                    </v-card-subtitle>

                    <!-- <v-card-text></v-card-text> -->

                    <v-card-actions>
                        <v-btn text color="purple darken-2" @click.prevent.stop="toggleDetail(item.license.id)">
                            Подробнее
                        </v-btn>
                    </v-card-actions>

                    <v-expand-transition>
                        <v-card v-if="isActive(item)" class="transition-fast-in-fast-out v-card--reveal" style="height: 100%;">
                            <v-card-text class="pb-0">
                                <v-list two-line subheader>
                                    <v-subheader>Данные активации</v-subheader>

                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-title>ФИО</v-list-item-title>
                                            <v-list-item-subtitle>{{ _.get(item, 'license.name', '–') }}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider></v-divider>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-title>Организация</v-list-item-title>
                                            <v-list-item-subtitle>{{ _.get(item, 'license.company', '–') }}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider></v-divider>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-title>E-mail</v-list-item-title>
                                            <v-list-item-subtitle>{{ _.get(item, 'license.email', '–') }}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider></v-divider>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-title>Адрес</v-list-item-title>
                                            <v-list-item-subtitle>{{ _.get(item, 'license.address', '–') }}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-card-text>
                            <v-card-actions class="pt-0">
                                <v-btn text color="purple darken-2" @click="toggleDetail(null)">
                                    Свернуть
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-expand-transition>
                </v-card>
            </v-col>
            <v-col v-else-if="$store.state.license.loading" cols="12">
                <v-progress-linear
                    indeterminate
                    color="purple darken-2"
                ></v-progress-linear>
            </v-col>
            <v-col v-else cols="12">
                <p class="text--disabled">Нет активированных лицензий.</p>
            </v-col>
        </v-row>

        <v-dialog v-model="dialog.activate" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card>
                <v-toolbar dark color="purple darken-2">
                    <v-toolbar-title>Активация ключа</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn icon dark @click="dialog.activate = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <div>
                    <register-form class="elevation-0" @success="onActivateSuccess" />
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { mapState } from 'vuex'
import RegisterForm from '@/components/auth/RegisterForm.vue'
import retrieveOrLoadMixin from '@/mixins/retrieveOrLoadMixin'

export default {
    mixins: [ retrieveOrLoadMixin ],
    components: { RegisterForm },
    data () {
        return {
            dialog: {
                activate: false
            },
            error: null,
            activeItemId: null
        }
    },
    computed: {
        ...mapState('license', ['items'])
    },
    methods: {
        isActive(item) {
            return item.license.id === this.activeItemId;
        },
        isExpiresSoon(item) {
            const now = parseInt(Date.now() / 1000);
            const monthInSeconds = 60 * 60 * 24 * 31;
            return now + monthInSeconds > item.license.expires_at;
        },
        isExpired(item) {
            const now = parseInt(Date.now() / 1000);
            return now > item.license.expires_at;
        },
        toggleDetail(id) {
            if(_.isNil(id))
                this.activeItemId = null;
            else
                this.activeItemId = id;
        },
        async onActivateSuccess() {
            await this.$store.dispatch('license/list', { pagination: 0 });
            this.dialog.activate = false;
        }
    }
}
</script>